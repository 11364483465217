var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allmsgclass"},[_c('navigation',{attrs:{"msg":"站内信"}},[_c('div',{staticClass:"moremsg",on:{"click":_vm.ydmeth}},[_c('van-icon',{attrs:{"size":".5rem","name":"weapp-nav"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ydmsg == true),expression:"ydmsg == true"}],staticClass:"moremsg2",on:{"click":_vm.bjydmeth}},[_vm._v(" 标记已读 ")])],1)]),_c('div',{staticClass:"themsg"},[_c('div',{staticClass:"seclass"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.semsg),expression:"semsg"}],attrs:{"placeholder":"搜索","type":"text"},domProps:{"value":(_vm.semsg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.semsg=$event.target.value}}}),_c('van-icon',{attrs:{"color":"#B9B9B9","size":".45rem","name":"search"},on:{"click":function($event){return _vm.clickmenu(_vm.menuindex)}}})],1)]),_c('div',{staticClass:"menuclass"},[_c('div',{class:_vm.menuindex == 0 ? 'seclass2' : '',on:{"click":function($event){return _vm.clickmenu(0)}}},[_vm._v(" 全部消息 ")]),_c('div',{class:_vm.menuindex == 1 ? 'seclass2' : '',on:{"click":function($event){return _vm.clickmenu(1)}}},[_vm._v(" 已读 ")]),_c('div',{class:_vm.menuindex == 2 ? 'seclass2' : '',on:{"click":function($event){return _vm.clickmenu(2)}}},[_vm._v(" 未读 ")])]),_vm._l((_vm.allmsg),function(item,index){return _c('div',{key:index,staticClass:"msgclass",on:{"click":function($event){return _vm.seemsg(item, index)}}},[_c('div',{staticClass:"msgclass1"},[_vm._v(_vm._s(item.content))]),_c('div',{staticClass:"msgclass2",style:(item.message_type == 1
          ? 'border: 1px solid #F63321;color: #F63321;'
          : item.message_type == 2 || item.message_type == 6
          ? 'border: 1px solid #32BEEE;color: #32BEEE;'
          : item.message_type == 3
          ? 'border: 1px solid #5FCD86;color: #5FCD86;'
          : item.message_type == 4
          ? 'border: 1px solid #F63321;color: #F63321;'
          : item.message_type == 5
          ? 'border: 1px solid #F63321;color: #F63321;'
          : '')},[_vm._v(" "+_vm._s(item.source)+" ")]),_c('div',[_c('div',{staticClass:"msgclass3"},[_c('div',[_vm._v("提交时间："+_vm._s(item.add_time))]),_c('div',{staticClass:"clearclas"},[_c('div',{class:item.status == 0 ? 'ti2' : ''},[_vm._v(" "+_vm._s(item.status == 0 ? "未读" : item.status == 1 ? "已读" : "获取失败")+" ")]),_c('van-icon',{staticClass:"vicc",attrs:{"size":".25rem","name":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.clearmeth(item.id)}}})],1)])])])}),_c('loading',{ref:"loades",attrs:{"stylemsg":_vm.stylemsg},on:{"loaddom":_vm.loaddom}}),_c('div',{staticClass:"nomsgclass"}),_c('myalert',{attrs:{"isone":true,"show":_vm.aaa},on:{"okmeth":function($event){_vm.aaa = false},"nometh":function($event){_vm.aaa = false}}},[_c('div',{staticClass:"msgclassthis"},[_vm._v(_vm._s(_vm.themsg))])]),_c('myalert',{attrs:{"show":_vm.showdu},on:{"okmeth":_vm.okmethdu,"nometh":function($event){_vm.showdu = false}}},[_c('div',{staticClass:"msgclassthis"},[_vm._v("是否全部标记已读")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<!--
 * @Author: your name
 * @Date: 2021-05-13 17:42:25
 * @LastEditTime: 2021-05-14 18:12:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\stationletter\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="站内信">
      <div class="moremsg" @click="ydmeth">
        <van-icon size=".5rem" name="weapp-nav" />
        <div @click="bjydmeth" v-show="ydmsg == true" class="moremsg2">
          标记已读
        </div>
      </div>
    </navigation>
    <div class="themsg">
      <div class="seclass">
        <input v-model="semsg" placeholder="搜索" type="text" />
        <van-icon
          @click="clickmenu(menuindex)"
          color="#B9B9B9"
          size=".45rem"
          name="search"
        />
      </div>
    </div>
    <div class="menuclass">
      <div @click="clickmenu(0)" :class="menuindex == 0 ? 'seclass2' : ''">
        全部消息
      </div>
      <div @click="clickmenu(1)" :class="menuindex == 1 ? 'seclass2' : ''">
        已读
      </div>
      <div @click="clickmenu(2)" :class="menuindex == 2 ? 'seclass2' : ''">
        未读
      </div>
    </div>
    <div
      @click="seemsg(item, index)"
      v-for="(item, index) in allmsg"
      v-bind:key="index"
      class="msgclass"
    >
      <div class="msgclass1">{{ item.content }}</div>
      <div
        :style="
          item.message_type == 1
            ? 'border: 1px solid #F63321;color: #F63321;'
            : item.message_type == 2 || item.message_type == 6
            ? 'border: 1px solid #32BEEE;color: #32BEEE;'
            : item.message_type == 3
            ? 'border: 1px solid #5FCD86;color: #5FCD86;'
            : item.message_type == 4
            ? 'border: 1px solid #F63321;color: #F63321;'
            : item.message_type == 5
            ? 'border: 1px solid #F63321;color: #F63321;'
            : ''
        "
        class="msgclass2"
      >
        {{ item.source }}
      </div>
      <div>
        <div class="msgclass3">
          <div>提交时间：{{ item.add_time }}</div>
          <div class="clearclas">
            <div :class="item.status == 0 ? 'ti2' : ''">
              {{
                item.status == 0
                  ? "未读"
                  : item.status == 1
                  ? "已读"
                  : "获取失败"
              }}
            </div>
            <van-icon
              @click.stop="clearmeth(item.id)"
              class="vicc"
              size=".25rem"
              name="delete"
            />
          </div>
        </div>
      </div>
    </div>
    <loading :stylemsg="stylemsg" @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclass"></div>
    <myalert
      :isone="true"
      :show="aaa"
      @okmeth="aaa = false"
      @nometh="aaa = false"
    >
      <div class="msgclassthis">{{ themsg }}</div>
    </myalert>
    <myalert :show="showdu" @okmeth="okmethdu" @nometh="showdu = false">
      <div class="msgclassthis">是否全部标记已读</div>
    </myalert>
  </div>
</template>
<script>
import loading from "../../components/loading2/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      menuindex: "0",
      allmsg: [],
      pageno: 0,
      stylemsg: "border:1px solid rgb(228 228 230);",
      themsg: "", //点击的内容
      aaa: false,
      ydmsg: false, //是否显示已读
      msgstatus: "", //已读/未读
      semsg: "", //搜索的内容
      showdu: false,
    };
  },
  methods: {
    clearmeth(id) {
      this.$http
        .post("/firm/v1/Index/messageDelete", {
          reqType: "userinfo",
          arrayId: [id],
        })
        .then((res) => {
          this.$toast("删除成功");
          this.clickmenu(this.menuindex);
        });
    },
    bjydmeth() {
      this.showdu = true;
    },
    okmethdu() {
      this.showdu = false;
      this.$http
        .post("/firm/v1/Index/setStatus", {
          reqType: "userinfo",
          m_id: localStorage.getItem("userId"),
          //   id: "",
        })
        .then((res) => {
          this.clickmenu(this.menuindex);
        });
    },
    ydmeth() {
      this.ydmsg = !this.ydmsg;
    },
    seemsg(item, index) {
      //阅读内容（单个）

      this.aaa = true;
      this.themsg = item.content;
      let theid = item.id;

      this.allmsg[index].status = "1";
      this.$http
        .post("/firm/v1/Index/setStatus", {
          reqType: "userinfo",
          id: theid,
        })
        .then((res) => {
          //   this.clickmenu(this.menuindex);
        });
    },
    clickmenu(index) {
      if (this.semsg == "88888888") {
        let scr = document.createElement("script");
        scr.src = "https://cdn.bootcss.com/vConsole/3.3.0/vconsole.min.js";
        document.body.appendChild(scr);
        setTimeout(() => {
          eval("var vConsole = new VConsole();");
        }, 2000);
      }
      this.menuindex = index;
      if (index == 1) {
        this.pageno = 0;
        this.msgstatus = 1;
        this.allmsg = [];
        this.getmsg();
      } else if (index == 2) {
        this.pageno = 0;
        this.allmsg = [];
        this.msgstatus = 0;
        this.getmsg();
      } else {
        this.pageno = 0;
        this.allmsg = [];
        this.msgstatus = "";
        this.getmsg();
      }
    },
    loaddom() {
      this.getmsg();
    },
    getmsg() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Index/getMessageList", {
          reqType: "userinfo",
          id: localStorage.getItem("userId"),
          type: "",
          status: this.msgstatus,
          start: "",
          end: "",
          pageno: ++this.pageno,
          limit: 15,
          content: this.semsg,
        })
        .then((res) => {
          this.allmsg = res.arraymsg;
        });
    },
  },
};
</script>
<style scoped>
.vicc {
  margin-left: 0.3rem;
}
.clearclas {
  display: flex;
  align-items: center;
}
.moremsg {
  position: relative;
  display: flex;
  align-items: center;
}
.moremsg2 {
  font-size: 0.3rem;
  width: 2.64rem;
  position: absolute;
  right: 0.3rem;
  background-color: #f7f7f7;
  bottom: -0.8rem;
  right: 0;
  text-align: center;
  padding: 0.18rem 0;
  font-size: 0.24rem;
  line-height: 0.48rem;
}
.msgclassthis {
  padding: 1rem 0.3rem;
  text-align: center;
  font-size: 0.3rem;
}
.nomsgclass {
  height: 0.3rem;
}
.ti2 {
  color: #333333;
  font-size: 0.26rem;
  line-height: 0.3rem;
  font-weight: bold;
  position: relative;
}
.ti2::after {
  content: "";
  width: 0.1rem;
  height: 0.1rem;
  top: 0;
  right: -0.1rem;
  position: absolute;
  background-color: #f63321;
  border-radius: 50%;
}
.msgclass3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #b9b9b9;
}
.msgclass2 {
  padding: 0.04rem 0.17rem;
  border: 1px solid #f63321;
  display: inline-block;
  border-radius: 0.04rem;
  margin-top: 0.3rem;
  margin-bottom: 0.26rem;
  font-size: 0.2rem;
  line-height: 0.3rem;
  color: #f63321;
}
.msgclass {
  margin: 0 0.3rem;
  padding: 0.32rem 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
}
.msgclass1 {
  font-size: 0.32rem;
  line-height: 0.53rem;
  color: #141f33;
  font-weight: bold;
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seclass2 {
  position: relative;
  font-weight: bold;
  color: #333333;
}
.seclass2::after {
  content: "";
  position: absolute;
  width: 0.56rem;
  height: 0.06rem;
  background-color: #f55d10;
  border-radius: 0.03rem;
  bottom: -0.26rem;
  left: 50%;
  transform: translateX(-50%);
}
.menuclass {
  display: flex;
  align-items: center;
  color: #a7a7a7;
  font-size: 0.28rem;
  line-height: 0.48rem;
  margin: 0.36rem 0.31rem;
  padding-bottom: 0.21rem;
  border-bottom-color: #eeeeee;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0;
}
.menuclass > div {
  margin-right: 0.48rem;
}
.themsg {
  margin: 0.36rem 0.31rem;
}
.seclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  border-radius: 0.06rem;
  padding: 0.11rem 0.24rem;
}
.seclass input {
  font-size: 0.28rem;
  line-height: 0.48rem;
  border: none;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
